// Code generated by Slice Machine. DO NOT EDIT.

import dynamic from "next/dynamic";

export const components = {
  hero: dynamic(() => import("./Hero")),
  image: dynamic(() => import("./Image")),
  list: dynamic(() => import("./List")),
  navigation: dynamic(() => import("./NavigationHeader")),
  navigation_footer: dynamic(() => import("./NavigationFooter")),
  page_intro: dynamic(() => import("./Text")),
  publication: dynamic(() => import("./Publication")),
  text_with_image: dynamic(() => import("./TextWithImage")),
};
